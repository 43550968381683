import { createRouter, createWebHistory } from "vue-router";
import isUserLoggedIn from "@/logic/isUserLoggedIn.js";

const routes = [
  {
    path: "/",
    name: "Main",
    component: () => import("../views/user/public/Main.vue"),
  },
  {
    path: "/registration",
    name: "Registration",
    component: () => import("../views/user/public/Registration.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/user/public/Login.vue"),
  },
  {
    path: "/regions",
    name: "Regions",
    component: () => import("../views/user/public/Regions.vue"),
  },
  {
    path: "/favorites",
    name: "Favorites",
    component: () => import("../views/user/public/Favorites.vue"),
  },
  {
    path: "/massagist/:id",
    name: "Massagist",
    component: () => import("../views/user/public/Massagist.vue"),
  },
  {
    path: "/drop-password",
    name: "DropPassword",
    component: () => import("../views/user/public/DropPassword.vue"),
  },
  {
    path: "/massagist-personal-page/:id",
    name: "MassagistPersonalPage",
    component: () => import("../views/user/private/MassagistPersonalPage.vue"),
    children: [
      {
        path: "massagist-anket",
        name: "MassagistAnket",
        component: () => import("@/views/user/private/MassagistAnket.vue")
      },
      {
        path: "photos",
        name: "Photos",
        component: () => import("@/views/user/private/MassagistPhotos.vue")
      },
      {
        path: "finance",
        name: "Finance",
        component: () => import("@/views/user/private/MassagistFinance.vue")
      },
      {
        path: "messages",
        name: "Messages",
        component: () => import("@/views/user/private/MassagistMessages.vue")
      },
      {
        path: "safety",
        name: "Safety",
        component: () => import("@/views/user/private/MassagistSafety.vue")
      },
      {
        path: "massagist-verify",
        name: "MassagistVerify",
        component: () => import("@/views/user/private/MassagistVerify.vue")
      },
    ]
  },
  {
    path: "/saloon-personal-page/:id",
    name: "SaloonPersonalPage",
    component: () => import("../views/user/private/SaloonPersonalPage.vue"),
    children: [
      {
        path: 'saloon-anket',
        name: "SaloonAnket",
        component: () => import("@/views/user/private/SaloonAnket.vue")

      }
    ]
  }

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if(to.fullPath.includes('massagist-personal-page')){
   const canAccess = await isUserLoggedIn.isMassagistLoggedIn();
   if(canAccess){
     next();
   } else {
     next({name: 'Login'})
   }
  } else {
    next();
  }
})

export default router;
