<template>
    <button class="button"
        :disabled="disabled"
        :class="{
        'animated-bb-center-ages' : animated && bb && ca && !disabled,
        'animated-bb-left-right' : animated && bb && lr && !disabled,
        'animated-bb-left-right-left' : animated && bb && lrl && !disabled,
        'animated-bbt-center-ages' : animated && bbt && ca && !disabled,
        'animated-bbt-left-right' : animated && bbt && lr && !disabled,
        'animated-bbt-left-right-left' : animated && bbt && lrl && !disabled,
    }"
    @click="$emit('action')"
    >{{ value }}</button>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            default: "кнопка"
        },
        disabled: {
            type: Boolean,
            default: false
        },
        animated: {
            type: Boolean,
            default: false
        },
        bb: {
            type: Boolean,
            default: false
        },
        bbt: {
            type: Boolean,
            default: false
        },
        ca: {
            type: Boolean,
            default: false
        },
        lr: {
            type: Boolean,
            default: false
        },
        lrl: {
            type: Boolean,
            default: false
        }
    },
    setup () {
        

        return {}
    }
}
</script>

<style lang="scss" scoped>
    .button{
        padding: 0.5em 1em;
        border: transparent;
        border-radius: 0.3em;
        background-color: transparent;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: 700;
        position: relative;
        
    }
    .animated-bb-left-right-left{
        &:after{
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: $accent-dark;
            transform-origin: left;
            transform: scaleX(0);
            transition: 0.3s ease;
        }
        &:hover.animated-bb-left-right-left:after{
            transform: scaleX(1);
        }
    }
    .animated-bb-left-right{
        &:after{
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: $accent-dark;
            transform-origin: left;
            transform: scaleX(0);
            transition: 0.3s ease;
        }
        &:hover.animated-bb-left-right:after{
            transform: scaleX(1);
            transform-origin: right;
        }
    }
    .animated-bb-center-ages{
        &:after{
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: $accent-dark;
            transform-origin: center;
            transform: scaleX(0);
            transition: 0.3s ease;
        }
        &:hover.animated-bb-center-ages:after{
            transform: scaleX(1);
            // transform-origin: right;         
        }
    }
    .animated-bbt-center-ages{
        &:after{
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: $accent-dark;
            transform-origin: center;
            transform: scaleX(0);
            transition: 0.3s ease;
        }
        &:before{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: $accent-dark;
            transform-origin: center;
            transform: scaleX(0);
            transition: 0.3s ease;
        }
        &:hover.animated-bbt-center-ages:after{
            transform: scaleX(1);
        }
        &:hover.animated-bbt-center-ages:before{
            transform: scaleX(1);
        }
    }
    .animated-bbt-left-right{
        &:after{
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: $accent-dark;
            transform-origin: left;
            transform: scaleX(0);
            transition: 0.3s ease;

        }
        &:before{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: $accent-dark;
            transform-origin: left;
            transform: scaleX(0);
            transition: 0.3s ease;
        }
        &:hover.animated-bbt-left-right:after{
            transform: scaleX(1);
            transform-origin: right;
        }
        &:hover.animated-bbt-left-right:before{
            transform: scaleX(1);
            transform-origin: right;
        }
    }
    .animated-bbt-left-right-left{
        &:after{
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: $accent-dark;
            transform-origin: left;
            transform: scaleX(0);
            transition: 0.3s ease;

        }
        &:before{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: $accent-dark;
            transform-origin: left;
            transform: scaleX(0);
            transition: 0.3s ease;
        }
        &:hover.animated-bbt-left-right-left:after{
            transform: scaleX(1);
        }
        &:hover.animated-bbt-left-right-left:before{
            transform: scaleX(1);
        }
    }
</style>