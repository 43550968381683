<template>
    <div class="base-input" :style="`transform: translateX(${inputState.animation.translateX}); height: ${height}${units}; width: ${width}${units}; margin: ${margin}`" :class="{ 'focused': inputState.isInputOnFocus && autocomplete }">
        <input 
            :type="inputType"
            :id="id"
            class="input-field"
            :class="{ 'input-field-outlined' : outlined,
                      'input-field-outlined-rounded' : rounded,
                      'input-field-outlined-valid' : inputState.valid && outlined,
                      'input-field-outlined-on-validation' : inputState.onValidation && outlined }"
            @focus="inputState.activateInput"
            @blur="inputState.disactivateInput"
            @input="inputState.getInputData($event.target.value)"
            @keyup.esc="inputState.dropInputData"
            v-model="inputState.inputData"
            :style="`height: ${height}${units}; width: ${width}${units}`"
            >
        <label :for="id" class="input-label" :class="{ 'label-active': inputState.isInputOnFocus, 'label-valid': inputState.valid, 'label-on-validation': inputState.onValidation }">{{ label }}</label>
        <div class="autocomplete" :class="{ 'opened' : inputState.autocomplete.isAutocompleteOpened }" v-if="autocomplete">
            <ul>
                <li v-if="inputState.inputData.length < 1">Введите название...</li>
                <li v-if="inputState.inputData.length > 0">Выберите из списка...</li>
                <li v-for="item, index in inputState.autocomplete.filtredData" :key="index"  @mouseover="inputState.chooseItem(item)" @click="inputState.chooseItemByClick(item)">{{ item }}</li>
            </ul>
        </div>
        <BaseCloseIcon class="input-close-icon" :class="{ 'input-close-icon-active' : inputState.isInputOnFocus, }" @action="inputState.dropInputData" />
        <span class="animated-bb-ca" :class="{ 'animated-bb-ca-active' : inputState.isInputOnFocus && !outlined, 'animated-bb-ca-active-valid': inputState.valid && !outlined, 'animated-bb-ca-active-on-validation': inputState.onValidation && !outlined }"></span>
        <div class="comment" v-if="inputState.comment.length > 0">{{ inputState.comment }}</div>
    </div>
</template>

<script>
import { reactive } from "vue";
    export default {
        props: {
            label: {
                type: String,
                default: "Текст"
            },
            id: {
                type: String,
                default: "input"
            },
            inputType: {
                type: String,
                default: 'text'
            },
            validation: {
                type: String,
                default: "name"
            },
            outlined: {
                type: Boolean,
                default: false
            },
            rounded: {
                type: Boolean,
                default: false
            },
            required: {
                type: Boolean,
                default: false
            },
            height: {
                type: String,
                default: "50"
            },
            width: {
                type: String,
                default: "200"
            },
            margin: {
                type: String,
                default: "0"
            },
            units: {
                type: String,
                default: "px"
            },
            autocomplete: {
                type: Boolean,
                default: false
            },
            autocompleteData: {
                type: Object,
                
            },
            prefiltredData: {
                type: Object,
        
            },
            password: {
                type: String,
                default: ""
            },
            minPay: {
                type: Number,
                default: 75
            },
            maxPay: {
                type: Number,
                default: 10000
            }
        },
        setup(props, { emit }){
            const inputState = reactive({
                isInputOnFocus: false,
                inputData: "",
                comment: "",
                valid: false,
                onValidation: false,
                animation: {
                    translateX: "0",
                    movingX: () => {
                        inputState.animation.translateX = "10%";
                        setTimeout(() => {
                        inputState.animation.translateX = "-10%";
                        }, 50)
                        setTimeout(() => {
                        inputState.animation.translateX = "10%";
                        }, 100)
                        setTimeout(() => {
                        inputState.animation.translateX = "-10%";
                        }, 150)
                        setTimeout(() => {
                        inputState.animation.translateX = "0";
                        }, 200)
                    }
                },
                autocomplete: {
                    filtredData: props.prefiltredData,
                    isAutocompleteOpened: false
                },
                activateInput: () => {
                    inputState.isInputOnFocus = true;
                    inputState.onValidation = true;
                    if(props.validation === "autocomplete"){
                        inputState.autocomplete.isAutocompleteOpened = true;
                    }
                },
                disactivateInput: () => {
                    if(props.required && !props.autocomplete){
                        if(inputState.inputData.length > 0 && !inputState.valid){
                            inputState.comment = 'Это обязательное поле!';
                            inputState.animation.movingX();
                            setTimeout(() => {
                                inputState.comment = "";
                            }, 2000)
                        } else if(inputState.inputData.length > 0 && inputState.valid) {
                            return
                        } else {
                            inputState.isInputOnFocus = false;
                            inputState.comment = 'Это обязательное поле!';
                            inputState.onValidation = true;
                            inputState.animation.movingX();
                            setTimeout(() => {
                                inputState.comment = "";
                                inputState.onValidation = false;
                            }, 2000)
                        }
                    } else if(props.required && props.autocomplete) {
                        setTimeout(() => {
                            if(inputState.inputData.length > 0 && !inputState.valid){

                                inputState.comment = "Это обязательное поле!";
                                inputState.animation.movingX();
                                setTimeout(() => {
                                inputState.comment = "";
                            }, 2000)
                            } else if(inputState.inputData.length < 1){
                                inputState.autocomplete.isAutocompleteOpened = false;
                                inputState.isInputOnFocus = false;
                                inputState.comment = "Это обязательное поле!";
                                inputState.animation.movingX();
                                setTimeout(() => {
                                inputState.comment = "";
                            }, 2000)
                            }


                        }, 100)
                    } else {
                        if(inputState.inputData.length > 0){
                            return;
                        } else {
                            inputState.isInputOnFocus = false;
                            inputState.autocomplete.isAutocompleteOpened = false;
                        }
                    }
                },
                getInputData: (data) => {
                    if(props.validation === "phone"){
                        inputState.onValidation = true;
                        const regExp = new RegExp("[^0-9]", "gi");
                        data = data.replace(regExp, "");
                        let firstNumber = "+7 ";
                        inputState.inputData = firstNumber + data.substring(1);
                        if(data.length > 1){
                            inputState.inputData = firstNumber + "(" + data.substring(1)
                        }
                        if(data.length > 4){
                            inputState.inputData = firstNumber + "(" + data.substring(1, 4) + ") " + data.substring(4)                  
                        }
                        if(data.length > 7){
                            inputState.inputData = firstNumber + "(" + data.substring(1, 4) + ") " + data.substring(4, 7) + " - " + data.substring(7)        
                        }
                        if(data.length > 9){
                            inputState.inputData = firstNumber + "(" + data.substring(1, 4) + ") " + data.substring(4, 7) + " - " + data.substring(7, 9) + " - " + data.substring(9, 11)   
                        }
                        if(data.length > 10){
                            inputState.onValidation = false;
                            inputState.valid = true;
                            const regExpression = new RegExp("[\\(\\)\\s\\-]", 'gi');
                            const dataToSend = inputState.inputData.replace(regExpression, "");
                            emit('sendData', dataToSend)
                        } else {
                            emit('sendData', "")
                        }
                    }
                    if(props.validation === "age"){
                        inputState.onValidation = true;
                        const regExp = new RegExp("[^0-9]", "gi");
                        if(data.match(regExp)){
                            data = data.replace(regExp, "");
                            inputState.inputData = data;
                        } else if (data < 18){
                            inputState.inputData = data;
                            inputState.comment = "Возраст должен быть больше 18!";
                        } else if (data > 99){
                            inputState.inputData = data.substring(0, 2);
                            inputState.comment = "Указывайте реальный возраст!";
                        } else {
                            inputState.inputData = data;
                            inputState.comment = "";
                            inputState.onValidation = false;
                            inputState.valid = true;
                            emit('sendData', inputState.inputData)
                        }
                    }
                    if(props.validation === "price"){
                        inputState.onValidation = true;
                        const regExp = new RegExp("[^0-9]", "gi");
                        if(data.match(regExp)){
                            data = data.replace(regExp, "");
                            inputState.inputData = data;
                        } else{
                            inputState.inputData = data;
                            inputState.onValidation = false;
                            inputState.valid = true;
                            emit('sendData', inputState.inputData)
                        }
                    }
                    if(props.validation === "pay"){
                        inputState.onValidation = true;
                        const regExp = new RegExp("[^0-9]", "gi");
                        if(data.match(regExp)){
                            data = data.replace(regExp, "");
                            inputState.inputData = data;
                        } else{
                            inputState.inputData = data;
                            if(data >= props.minPay && data <= props.maxPay){
                                inputState.onValidation = false;
                                inputState.valid = true;
                            }
                            emit('sendData', inputState.inputData)
                        }
                    }
                    if(props.validation === "name"){
                        const regExp = new RegExp("[0-9A-Za-z]", "gi");
                        if(data.match(regExp)){
                            inputState.comment = "Имя должно содержать только киррилические символы";
                            setTimeout(() => {
                                inputState.comment = ""
                            }, 2000)
                        }
                        data = data.replace(regExp, "");
                        inputState.inputData = data;
                        if(data.length > 2){
                            inputState.valid = true;
                            inputState.onValidation = false;
                            emit('sendData', inputState.inputData)
                        } else {
                            emit('sendData', "")
                        }
                    }
         
                    if(props.validation === "login"){
                        const regExp = new RegExp("[А-Яа-я]", "gi");
                        if(data.match(regExp)){
                            inputState.comment = "Логин должен содержать латинские символы и (или) цифры";
                            setTimeout(() => {
                                inputState.comment = ""
                            }, 2000)
                        }
                        data = data.replace(regExp, "");
                        inputState.inputData = data;
                    }
                    if(props.validation === "email"){
                        const regExp = new RegExp("[А-Яа-я]", "gi");
                        if(data.match(regExp)){
                            inputState.comment = "Email должен содержать латинские символы и (или) цифры";
                            setTimeout(() => {
                                inputState.comment = ""
                            }, 2000)
                        } else {
                            inputState.valid = true;
                            inputState.onValidation = false;
                            data = data.replace(regExp, "");
                            inputState.inputData = data;
                            emit('sendData', inputState.inputData)
                        }
                    }
                    if(props.validation === "password"){
                        const regExp = new RegExp("[А-Яа-я]", "gi");
                        if(data.match(regExp)){
                            inputState.comment = "Пароль должен содержать латинские символы и (или) цифры и быть длиннее 5 символов";
                            setTimeout(() => {
                                inputState.comment = ""
                            }, 2000)
                        }
                        data = data.replace(regExp, "");
                        inputState.inputData = data;
                        if(data.length > 5){
                            inputState.valid = true;
                            inputState.onValidation = false;
                            inputState.comment = "";
                            emit('sendData', inputState.inputData)
                        } else {
                            inputState.comment = "Пароль должен содержать латинские символы и (или) цифры и быть длиннее 5 символов";
                        }
                    }
                    if(props.validation === "repeate"){
                        if(props.password === data){
                            inputState.valid = true;
                            inputState.onValidation = false;
                            emit('sendData', inputState.inputData)
                            inputState.comment = ""
                        } else {
                            inputState.comment = "Пароли не совпадают!"
                            emit('sendData', "")
                        }
                    }
                    if(props.validation === "autocomplete"){
                        inputState.valid = false;
                        inputState.onValidation = true;
                        emit("sendData", "")
                        inputState.autocomplete.filtredData = props.autocompleteData.filter(item => {
                            let regexp = new RegExp(`^${data}`, "gi");
                            if(item.match(regexp)){
                                return item;
                            }
                        })
                        if(inputState.inputData === ""){
                            inputState.autocomplete.filtredData = props.prefiltredData;
                        }
                    }

                },
                dropInputData: () => {
                    inputState.inputData = "";
                    inputState.valid = false;
                    inputState.onValidation = false;
                    inputState.autocomplete.filtredData = props.prefiltredData;
                    inputState.disactivateInput();
                    emit('sendData', "")
                },
                chooseItem: (item) => {
                    inputState.inputData = item;
                },
                chooseItemByClick: (item) => {
                    inputState.inputData = item;
                    inputState.onValidation = false;
                    inputState.valid = true;
                    inputState.autocomplete.isAutocompleteOpened = false;
                    emit('sendData', inputState.inputData)
                }


            });

            const escapeFunc = () => {
                console.log(1)
            }
   
    
            return {                
                inputState,
                escapeFunc
            }
        }
    }
</script>

<style lang="scss" scoped>
    .base-input{
        position: relative;
        transition: 0.05s ease;
        .validated-data{
            position: absolute;
            top: 0;
            left: 0;
        }
        .comment{
            position: absolute;
            bottom: -60%;
            left: 0;
            font-size: 0.7em;
            color: $error;
        }
        .input-field{
            transform-origin: center;
            transition: border 1s ease;
            position: relative;
            border: 1px solid transparent;
            cursor: pointer;
            padding: 0 1em 0 1em;
            &:hover{
                border-bottom: 1px solid $accent-dark;
            }
            &:focus{
                outline: none;
                z-index: 30000;
                
            }
        }
        .input-field-outlined{
            border: 1px solid $accent-dark;
            transition: 0.3s ease;
            &:hover{
                box-shadow: 0px 0px 10px 1px $accent;
            }
            &:focus{
                box-shadow: 0px 0px 10px 1px $accent;
            }
        }
        .input-field-outlined-valid{
            border: 1px solid $success;
        }
        .input-field-outlined-on-validation{
            border: 1px solid $error;
             &:hover{
                border-bottom: 1px solid $error;
            }
        }
        .input-field-outlined-rounded{
            border-radius: 0.4em;
        }
        .animated-bb-ca{
            position: absolute;
            left: 0;
            bottom: 0;
            height: 2px;
            width: 100%;
            background-color: $accent-dark;
            transform: scaleX(0);
            transform-origin: center;
            transition: 0.3s ease;
            z-index: 200;
        }
        .animated-bb-ca-active{
            transform: scaleX(1);
        }
        .animated-bb-ca-active-valid{
            background-color: $success;
        }
        .animated-bb-ca-active-on-validation{
            background-color: $error;
        }

        .input-label{
            position: absolute;
            top: 50%;
            left: 1em;
            transform: translateY(-50%);
            transition: 0.3s ease;
            cursor: pointer;
            color: $black;
            z-index: 30001;
        }
        .label-active{
            top: 0;
            font-size: 0.7em;
            background-color: $white;
            padding: 0.2em 0.4em;
            font-weight: 700;
        }
        .label-valid{
            color: $success;
        }
        .label-on-validation{
            color: $error;
        }
        .input-close-icon{
            opacity: 0;
            transition: 0.3s ease;
            position: absolute;
            top: 50%;
            right: 1em;
            transform: translateY(-50%);
            cursor: pointer;
            color: $accent-dark;
            z-index: 200;
        }
        .input-close-icon-active{           
            opacity: 0.5;            
            &:hover{
                opacity: 1;
            }
            
        }
        .input-close-icon-active-valid{
            color: $success;
        }
        .input-close-icon-active-on-validation{
            color: $error;
        }
        .block-number-arrows{
            width: 2em;
            height: 100%;
            background-color: $white;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 100;
        }
        .autocomplete{
            position: absolute;
            top: 100%;
            left: 0;
            height: auto;
            max-height: 200px;
            width: 100%;
            overflow: hidden;
            background-color: $white;
            border-radius: 0.4em;
            transition: 0.3s ease;
            display: none;
            border: 2px solid $accent-dark;
            border-top: 1px solid $accent-dark;

            ul{
                list-style: none;
               
                li{
                    padding: 0.3em 1em;
                    cursor: pointer;
                    transition: 0.3s ease;
                    &:hover{
                        background-color: $primary;
                    }
                }
            }
        }
        .opened{
            display: block;
            
            
        }
    }
    .focused{
        z-index: 20000;
    }
</style>