import store from "@/store";

export default {
    async isMassagistLoggedIn(){
    const userStatus = await store.state.user.status;
    if(userStatus === 'massagist' || userStatus === 'saloon'){
        return true;
    } else {
        return false;
    }
    }
}