<template>
  <router-view />
</template>

<script>
import { useStore } from 'vuex';
import { onMounted } from 'vue';
export default {
  setup(){
    const store = useStore();

    const isUserLoggedIn = () => {
      const user = JSON.parse(localStorage.getItem('user'));
      if(user){
        store.dispatch('setUser', user)
        return true;
      } else {
        return false;
      }
    }

    onMounted(() => {
      isUserLoggedIn();      
    })
  }
}
</script>
