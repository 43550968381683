import { createStore } from "vuex";

export default createStore({
  state: {
    user: {
      token: null,
      isLoggedIn: false,
      status: null,
      finance: null,
      user: {
        mainPhoto: null,
      }
    },
    admin: {
      token: "",
      isLoggedIn: false,
      id: null
    },
    location: "Москва",
    massagists: [],
    isModalToGetLocationOpened: false,
    isModalToSetLocationOpened: false,
    massagists: [],
    saloons: [],
    mobilePrivateNavigation: [
      {
        id: 1,
        name: "Анкета",
        active: true,
        link: `massagist-anket`
    },
    {
        id: 2,
        name: "Фотографии",
        active: false,
        link: 'photos'
    },
    {
        id: 3,
        name: "Финансы",
        active: false,
        link: 'finance'
    },
    // {
    //     id: 4,
    //     name: "Сообщения",
    //     active: false,
    //     link: 'messages'
    // },
    {
        id: 4,
        name: "Безопасность",
        active: false,
        link: 'safety'
    },
    {
        id: 5,
        name: "Верификация аккаунта",
        active: false,
        link: 'massagist-verify'
    }
    ],
  },
  mutations: {
    setCurrentPageInMobyleNavigationPanel(state, id){
      state.mobilePrivateNavigation.forEach(item => {
        item.id === id ? item.active = true : item.active = false;
      })
    },
    dropCurrentPageInMobyleNavigationPanel(state){
      state.mobilePrivateNavigation.forEach(item => {
        item.id === 1 ? item.active = true : item.active = false;
      })
    },
    setUser(state, user){
      state.user.token = user.token;
      state.user.isLoggedIn = true;
      state.user.status = user.status;
      state.user.user = user.user;
      state.user.finance = user.finance;
      state.user.user.mainPhoto = user.mainPhoto;

    },
    setOnlyUserData(state, user){
      state.user.user = user;
    },
    setUserMainPhoto(state, photo){
      state.user.user.mainPhoto = photo.link;
    },
    dropUserMainPhoto(state){
      state.user.user.mainPhoto = 'user.png';
    },
    setNewUserEmail(state, email){
      state.user.user.email = email;
    },
    logout(state){
      state.user.token = "";
      state.user.isLoggedIn = false;
      state.user.status = "";
      state.user.user = {};
      state.admin.token = "";
      state.admin.isLoggedIn = false;
      state.admin.id = null;

    },
    setLocation(state, location){
      state.location = location;
    },
    openModelToGetLocation(state){
      state.isModalToGetLocationOpened = true;
    },
    closeModelToGetLocation(state){
      state.isModalToGetLocationOpened = false;
      console.log(1)
    },
    openModalToSetLocation(state){
      state.isModalToSetLocationOpened = true;
    },
    closeModalToSetLocation(state){
      state.isModalToSetLocationOpened = false;
    },
    setMassagists(state, massagists){
      state.massagists = massagists;
    },
    setMassagistBalance(state, finance){
      state.user.finance.balance = finance.balance;
      state.user.finance.bonus = finance.bonus;
    }
  },
  actions: {
    setCurrentPageInMobyleNavigationPanel({commit}, id){
      commit('setCurrentPageInMobyleNavigationPanel', id)
    },
    dropCurrentPageInMobyleNavigationPanel({commit}){
      commit('dropCurrentPageInMobyleNavigationPanel')
    },
    setUser({commit}, user ) {
      commit('setUser', user )
    },
    setOnlyUserData({commit}, user ) {
      commit('setOnlyUserData', user )
    },
    setUserMainPhoto({commit}, photo) {
      commit('setUserMainPhoto', photo)
    },
    dropUserMainPhoto({commit}) {
      commit('dropUserMainPhoto')
    },
    setNewUserEmail({commit}, email){
      commit('setNewUserEmail', email)
    },
    logout({commit}){
      commit('logout')
    },
    setLocation({commit}, location){
      commit('setLocation', location)
    },
    openModelToGetLocation({ commit }){
      commit('openModelToSetLocation')
    },
    closeModelToGetLocation({ commit }){
      commit('closeModelToGetLocation')
    },
    openModalToSetLocation({commit}){
      commit('openModalToSetLocation')
    },
    closeModalToSetLocation({commit}){
      commit('closeModalToSetLocation')
    },
    setMassagists({commit}, massagists){
      commit('setMassagists', massagists)
    },
    setMassagistBalance({commit}, finance){
      commit('setMassagistBalance', finance)
    }
  },
  getters: {
    getMassagistByID: (state, id) => {
      // return state.massagists.filter(massagist => massagist.id === id);
      console.log(id)
    }
  },
  modules: {},
});
